import { AccountProfileStoredData } from '../models/AccountProfileStoredData'
import { AccountProfileFormData } from '../models/AccountProfileFormData'
import { mockAccountProfileFormData } from '../__mockery__/mockAccountProfileFormData'
import { CUSTOMER_PROFILE_PERSONALIZATION_API_URL, REQUESTOR } from '../utils/constants'
import { buildEmptyAccountProfileStoredData } from '../factories/accountProfileStoredDataFactory'
import { Logger } from '@vp/ubik-logging'

export const fetchAccountProfileFormData = async (locale: string): Promise<AccountProfileFormData> => {
  return mockAccountProfileFormData()
}

export const fetchAccountProfileStoredData = async (
  props: { canonicalId: string, authorizationHeader: string, logger: Logger }
): Promise<AccountProfileStoredData> => {
  const { canonicalId, authorizationHeader, logger } = props

  const response = await fetch(CUSTOMER_PROFILE_PERSONALIZATION_API_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorizationHeader,
      'x-requestor': REQUESTOR
    }
  })

  if (response.status === 204 || response.status === 404) {
    logger.warn('No account profile data found for canonical ID:', canonicalId)
    return buildEmptyAccountProfileStoredData()
  }

  if (!response.ok) {
    logger.error('Failed to fetch account profile data:', response)
    throw new Error('Failed to fetch account profile data')
  }

  const accountProfileStoredData = await response.json() as AccountProfileStoredData
  return accountProfileStoredData
}

export const storeAccountProfileData = async (
  props: { accountProfileDataToStore: AccountProfileStoredData, canonicalId: string, authorizationHeader: string, logger: Logger }
) => {
  const { accountProfileDataToStore, authorizationHeader, logger } = props

  const response = await fetch(CUSTOMER_PROFILE_PERSONALIZATION_API_URL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorizationHeader,
      'x-requestor': REQUESTOR
    },
    body: JSON.stringify(accountProfileDataToStore)
  })

  if (!response.ok) {
    logger.error('Failed to store account profile data:', response)
    throw new Error('Failed to store account profile data')
  }
}

export const deleteAccountProfileStoredData = async (props: { canonicalId: string, authorizationHeader: string, logger: Logger }) => {
  const { canonicalId, authorizationHeader, logger } = props

  const response = await fetch(CUSTOMER_PROFILE_PERSONALIZATION_API_URL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorizationHeader,
      'x-requestor': REQUESTOR
    }
  })

  if (!response.ok) {
    if (response.status === 404) {
      logger.warn('No account profile data found for canonical ID:', canonicalId)
      return
    }
    logger.error('Failed to delete account profile data:', response)
    throw new Error('Failed to delete account profile data')
  }
}
